import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { Section, BreadCrumb } from '../components/Section'
import RequestForm from '../components/RequestForm'
import BreakpointDown from '../components/Media/BreakpointDown'
import PhoneIcon from '../components/Icons/PhoneIcon'

const ContactUsTitle = styled.div`
letter-spacing: 13.56px;
color: #000000;
text-transform: uppercase;
opacity: 0.04; 
position:absolute;
text-align:center;
z-index:2;
left:0;
right:0;
font-weight:900;
font-family: "Nunito Sans", sans-serif;
font-size:226px;
margin-top:60px;
@media(max-width:1600px){
   font-size:150px;  
}
${BreakpointDown.xl`
 font-size:100px; 
`}
${BreakpointDown.lg`
 font-size:80px; 
`}
${BreakpointDown.md`
 font-size:50px; 
`}
`
const SectionBefore = styled(Section)`
border-bottom:1px solid #CCCCCC;
.container{ z-index:2;}
  &:before{
     left:0;
     width:40%;
     ${BreakpointDown.lg`
        width:100%;
     `}
  }
`
const ContactUsRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-15px;
 margin-right:-15px;
 margin-top: 25px;
 align-items:center;
`
const ContactUsColumnLeft = styled.div`
 flex:0 0 40%;
 width:40%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.lg`
    flex:0 0 100%;
    width:100%;
    margin-bottom:30px;
 `}
`
const ContactUsColumnRight = styled.div`
 flex:0 0 60%;
 width:60%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.lg`
    flex:0 0 100%;
    width:100%;
    background:#fff;
    padding-top:30px;
 `}
`
const BreadCrumbWhite = styled(BreadCrumb)`
 a{
   color:#fff;
   &[href*="#"]{
     color:#cccccc;
   }
   &:hover{
     color:#fff;
   }
 }
`
const PageTitle = styled.h1`
  color:#fff;
`
const ContactList = styled.div`
  margin-top:30px;
  max-width:376px;
  line-height:26px;
`
const ContactListItem = styled.div`
 display:flex;
 color:#fff;
 font-size:16px;
 align-items:center;
 ${BreakpointDown.lg`
  font-size:14px;
 `}
 a{
   color:#fff;
 }
 & + &{
   margin-top:20px;
   ${BreakpointDown.sm`
    margin-top:10px;
   `}
 }

`
const IconWrapper = styled.div`
  width:50px;
  height:50px;
  display:flex;
  flex:0 0 50px;
  margin-right:20px;
  background:#6EA132;
  border:1px solid #4D9000;
  border-radius:100%;
  align-items:center;
  justify-content:center;
  ${BreakpointDown.md`
    width:40px;
    height:40px;
    flex:0 0 40px;
    align-item:center;
  `}
 svg{
   width:24px;
   height:24px;
   path{
     fill:#fff;
   }
 }
`
const FormWrapper = styled.div`
 padding: 0 60px;
 ${BreakpointDown.lg`
 padding: 0 30px;
 `}
`
const FormTitle = styled.div`
margin-bottom:0;
`
const FormSubTitle = styled.div`
  font-size:14px;
  color:#666666;
  margin-bottom:30px;
`

function ContactUsPage() {
  return (
    <Layout pageName="contact-us">
      <SEO title="Contact Us"></SEO>
      <SectionBefore bgBefore="#73A935" pt="170px" pb="100px" xpt="90px" xpb="0px" mpb="0">
        <ContactUsTitle>Contact US</ContactUsTitle>
        <div className="container">
            <BreadCrumbWhite>
              <Link to="/">Home</Link><Link to="/#">Contact Us</Link>
            </BreadCrumbWhite>
            <ContactUsRow>
              <ContactUsColumnLeft>
                <PageTitle className="h2">WYN Technologies Inc</PageTitle>
                <ContactList>
                  <ContactListItem>
                    <IconWrapper>
                      <PhoneIcon />
                    </IconWrapper>
                        325 Habersham Road, Suite 103, High Point, North Carolina 27260, United States
                    </ContactListItem>
                  <ContactListItem>
                    <IconWrapper>
                      <StaticImage
                        src="../svg/marker.svg"
                        quality={100}
                        alt="Marker"
                        placeholder="blurred"
                      />
                    </IconWrapper>
                    <a href="tel:3368990555">(336) 899-0555</a>
                  </ContactListItem>
                  <ContactListItem>
                    <IconWrapper>
                      <StaticImage
                        src="../svg/email.svg"
                        quality={100}
                        alt="Email"
                        placeholder="blurred"
                      />
                    </IconWrapper>
                    <a href="mailto:wyntechnologies@info.com">wyntechnologies@info.com</a>
                  </ContactListItem>
                </ContactList>
              </ContactUsColumnLeft>
              <ContactUsColumnRight>
                <FormWrapper>
                  <FormTitle className="h2">Just say hi!</FormTitle>
                  <FormSubTitle>Tell us more about you and we’ll contact you soon :)</FormSubTitle>
                  <RequestForm />
                </FormWrapper>
              </ContactUsColumnRight>
            </ContactUsRow>
        </div>
      </SectionBefore>
    </Layout>
  )
}

export default ContactUsPage

